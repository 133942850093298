//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      lists: [],
    };
  },
  created() {
      this.lists = JSON.parse(localStorage.getItem("returnA"));
  },
  mounted() {
  },
  methods: {
    login() {
      this.$router.push(this.lists[0].children[0].path);
    },
    exit(){
      this.$confirm('是否确认退出', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //删除token
          this.$cookies.remove("token");
          //跳回首页
          this.$router.push("/homes/login");
          localStorage.clear();
          sessionStorage.clear();
        })
    }
  },
};
